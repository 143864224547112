<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VCardTitle class="justify-space-between">
            <span>Добавление контента в топик {{ topicKey }}</span>
            <VBtn
              depressed
              color="primary"
              :to="{ name : Names.R_CONTENT_STORAGE_COURSE_TOPICS_EDIT, query : { ...$route.query } }"
            >
              <VIcon left>
                fal fa-chevron-left
              </VIcon>
              Назад к топикам
            </VBtn>
          </VCardTitle>
          <VCardText>
            <VRow v-if="currentTopic && currentTopic.items">
              <VCol cols="6">
                <h2 class="text-h2">
                  Контент
                </h2>
                <Draggable
                  :list="currentTopic.items"
                  @change="updateCourse"
                >
                  <div
                    v-for="item in currentTopic.items"
                    :key="item.id"
                  >
                    <div class="d-flex justify-space-between my-2">
                      <p class="mb-0">
                        {{ item.content.title }}
                      </p>
                      <div>
                        <VBtn
                          icon
                          small
                          color="red"
                          @click="removeContentFromTopic(item.id)"
                        >
                          <VIcon small>
                            fal fa-trash
                          </VIcon>
                        </VBtn>
                        <VBtn
                          icon
                          color="warning"
                          :to="{
                            name : Names.R_CONTENT_STORAGE_CONTENT_EDIT,
                            params : {
                              contentId : item.id
                            }
                          }"
                        >
                          <VIcon small>
                            fal fa-edit
                          </VIcon>
                        </VBtn>
                      </div>
                    </div>
                    <VDivider />
                  </div>
                </Draggable>
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <CourseContentTableEdit
                  @add:content="addContent"
                  @update:content="updateTopicContent"
                />
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import Draggable from 'vuedraggable';
import { queryParse } from '@front.backoffice/common';
import CourseContentTableEdit from '../../../components/contentStorage/courses/CourseContentTableEdit.vue';

export default {
  name: 'CourseContentAdd',
  components: { CourseContentTableEdit, Draggable },
  inject: ['Names'],
  data() {
    return {
      topics: [],
      cachedCourse: {},
    };
  },
  computed: {
    topicKey() {
      return this.$route.params.topicKey;
    },
    currentTopic() {
      return this.topics.find((topic) => topic.key === this.topicKey);
    },
  },
  created() {
    if (!this.$route.query.labels) {
      this.$di.notify.snackError('Отсутствуют метки в адресе');
      return;
    }
    this.getCourse();
  },
  methods: {
    getCourse() {
      const { labels: routeLabels } = queryParse(this.$route.query.labels);

      const payload = {
        labels: routeLabels,
        pagination: {
          limit: 100,
        },
      };

      /**
       * TODO: разобраться совместно с бэкендом с этим бардаком
       * Note: Делаем первый запрос для получения topicKeys
       * Это понадобится для запроса всего контента
       */
      this.$di.api.ContentStorage
        .courseItemGet(payload)
        .then((response) => {
          payload.topics = response.topicKeys;
          payload.pagination = {
            ...response.pagintaion,
            limit: response.total,
          };

          /**
           * Note: Делаем второй запрос, чтобы забрать ВЕСЬ контент
           * потому что если бэкенду не прислать его обратно - он удалит весь контент
           */
          this.$di.api.ContentStorage
            .courseItemGet(payload)
            .then((fullCourse) => {
              this.topics = fullCourse.topics;
              this.cachedCourse = {
                ...fullCourse.course,
              };
            })
            .catch(this.$di.notify.errorHandler);
        })
        .catch(this.$di.notify.errorHandler);
    },
    addContent(content) {
      this.updateTopicContent([...this.currentTopic.items, content]);
    },
    removeContentFromTopic(id) {
      // Note: проверка нужна, потому что может придти ключ all без items
      if (this.currentTopic.items) {
        this.updateTopicContent(this.currentTopic.items.filter((item) => item.id !== id));
      }
    },
    // Note: контент в топике перезаписывается полностью
    updateTopicContent(contents) {
      const targetIndex = this.topics.findIndex((topic) => topic.key === this.topicKey);
      const target = cloneDeep(this.topics[targetIndex]);
      const start = this.topics.slice(0, targetIndex);
      const end = this.topics.slice(targetIndex + 1);

      target.items = [...contents];

      this.topics = [...start, target, ...end];
      this.updateCourse();
    },
    updateCourse() {
      // Note: для сохранения топиков требуется в items посылать id контента, а не сам контент
      const topicsWithContentId = this.topics.map((topic) => {
        // Note: В топике вместо набора контента может ничего не придти (это будет ключ all)
        if (!topic.items) {
          return topic;
        }

        return {
          ...topic,
          items: topic.items.map((item) => item.id),
        };
      });

      const payload = {
        ...omit(this.cachedCourse, ['id', 'topicKeys']),
        topics: topicsWithContentId,
      };

      this.$di.api.ContentStorage.courseItemEdit(payload).catch(this.$di.notify.errorHandler);
    },
  },
};
</script>
