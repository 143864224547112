var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.pagination.total,"loading":_vm.contentLoading},on:{"pagination":_vm.handlePagination},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(item.content.title)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(" "+_vm._s(item.contentType)+" ")])]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(" "+_vm._s(item.content.body)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.contentTypes.videoHLS === item.contentType)?[(_vm.CONTENT_STATUS.STATUS_ERROR === item.status)?_c('VBtn',{attrs:{"depressed":"","icon":"","color":"warning"},on:{"click":function($event){return _vm.reuploadContent(item.id)}}},[_c('VIcon',[_vm._v("fal fa-redo")])],1):_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.status || 'Нет статуса')+" ")])]:_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('VBtn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.addContent(item)}}},[_c('VIcon',{attrs:{"small":""}},[_vm._v(" far fa-plus ")])],1),_c('VBtn',{attrs:{"icon":"","color":"warning","to":{
        name : _vm.Names.R_CONTENT_STORAGE_CONTENT_EDIT,
        params : {
          contentId : item.id
        }
      }}},[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }